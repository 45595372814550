div.landingPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 8vw;
}

div.landingPage div.aboutme {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  padding: 0 30px;
  text-align: center;
}

div.landingPage div.aboutme img {
  border-radius: 12px;
  margin: 1rem;
  max-width: 200px;
  max-height: 200px;
}

div.landingPage div.aboutme p{
  margin-bottom: 0.4rem;
}

div.landingPage div.intro {
  font-size: calc(1rem + 0.8vh);
  padding: calc(20px + 5vh) 5vw;
  text-align: center;
}

div.landingPage div.intro h1{
  display: inline;
  font-size: calc(1rem + 0.8vh);
  font-weight: normal;
  margin: 0;
}

div.landingPage div.intro strong {
  white-space: nowrap;
}

div.landingPage h1 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 991px) {
  div.landingPage div.lpContent {
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 992px) {
  div.landingPage div.intro {
    flex: 1;
    max-height: 300px;
  }
}
