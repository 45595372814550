div.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

div.App main {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: scroll;
  padding-bottom: 28px;
  width: 100%;
}

div.App main div.content{
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
}
