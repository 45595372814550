div.coreVal {
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(60,64,67,0.3);
  margin: 16px 0;
  padding: 1.2rem;
}

div.coreVal div.body {
  align-items: center;
  display: flex;
  height: inherit;
}

div.coreVal div.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.coreVal div.icon {
  align-items: center;
  background-color: #888888;
  border-radius: calc(3rem / 2);
  display: flex;
  height: 3rem;
  justify-content: center;
  width: 3rem;
}

div.coreVal div.icon span {
  color: #fff;
  font-size: 1.4rem;
}

div.coreVal h2 {
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-top: 8px;
}

@media only screen and (max-width: 575px) {
  div.coreVal {
    padding: 2rem 1rem;
  }
  div.coreVal div.body{
    justify-content: center;
    text-align: center;
  }
}
