nav {
  box-shadow: 0 2px 4px 0 rgba(60,64,67,0.3);
  display: flex;
  height: 3rem;
  width: 100vw;
}

nav a.logo {
  align-items: center;
  display: flex;
  padding: 0 1rem;
}

nav a.logo img {
  height: 2rem;
}

nav div.links {
  align-items: center;
  display: flex;
  margin-left: 1rem;
}

nav div.links a {
  color: #0F0F0F;
  font-weight: 500;
  text-decoration: none;
}

nav div.links a span{
  margin: 0 0.5rem;
  padding: 2px;
}

nav div.links a span.active{
  border-bottom: solid #888888 2px;
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  nav div.links {
    flex: 1;
    justify-content: flex-end;
  }
}
