div.SocialItem {
  display: flex;  
  flex: 1;
  min-width: 120px;

  margin-bottom: 0.25rem;

  font-size: 0.8rem;

  border: solid blue 0px;
}

div.SocialItem div {
  display: flex;
  align-items: flex-end;

  border: solid red 0px;
}

div.SocialItem span, div.SocialItem svg {
  margin-right: 4px;
}
