div.contactPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: calc(20px + 2vh);
}

div.contactPage h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}