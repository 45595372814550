div.certItem {
  display: flex;

  font-size: 0.8rem;

}

div.certItem div.image {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-right: 0.75rem;

}

div.certItem div.image img {
  height: 3rem;
  width: 3rem;
}

div.certItem div.info {
  flex: 1;
}

div.certItem div.info div.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 0.25rem;
}

div.certItem div.info div.header span.name {
  font-size: 1rem;
  font-weight: 500;
  
}

div.certItem div.info div.header span.period {
  font-size: 0.7rem;
}
