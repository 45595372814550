div.resumePage {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: calc(20px + 2vh);
  padding-bottom: calc(50px + 10vh);
}

div.resumePage div.base h2.name {
  font-size: 2rem;
  font-weight: bold;
}

div.resumePage div.base div.title {
  font-size: 1rem;
  font-weight: bold;

  margin-bottom: 1rem;
}

div.resumePage div.base div.social {
  display: flex;
  flex-wrap: wrap;
  
  margin-bottom: 0.8rem;
 
}

div.resumePage div.experience li.expListItem {
  list-style: none;
}

div.resumePage div.experience ul.expList {
  padding-left: 0;
}

div.resumePage div.experience h2 {
  font-size: 0.8rem;
  text-decoration: underline;
}

div.resumePage div.skills h2 {
  font-size: 0.8rem;
  text-decoration: underline;
}

div.resumePage div.skills ul.childList {
  display: flex;
  flex-wrap: wrap;

  padding-left: 1rem;

  list-style: none;
  font-size: 0.825rem;

}

div.resumePage div.skills ul.childList li {
  margin: 0.2rem;
  border: solid #d63384 1px;
  border-radius: 6px;
  padding: 2px 8px;
}

div.resumePage div.skills ul.parentList {
  list-style: none;
  padding-left: 0.5rem;

  font-size: 0.9rem;
}

div.resumePage div.certs h2 {
  font-size: 0.8rem;
  text-decoration: underline;
}

div.resumePage div.certs ul {
  list-style: none;
  padding-left: 0.5rem;
}

div.resumePage h1 {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
