div.expItem {
  margin-bottom: 2rem;
}

div.expItem div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.expItem li {
  list-style: url('../../../assets/icons/expBullet.svg'); 
}

div.expItem span.bullet { font-size: 0.8rem; }

div.expItem span.company { 
  font-size: 1rem;
  font-weight: 600;
}

div.expItem span.location { 
  margin-left: 0.4rem;
  font-size: 0.8rem;
}

div.expItem span.title { 
  padding-left: 4px;
  
  font-size: 0.8rem;
  font-style: italic;
}

